body {
    background-size: auto;
}

.ml-2 {
    margin-left: 20px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.mt-5 {
    margin-top: 35px !important;
}

.auth-box {
    border: 1px solid rgb(255 255 255 / 23%);
    padding: 15px;
    background-color: rgb(255 255 255 / 23%);
}

.text-right {
    text-align: right !important;
}

.dc-count {
    font: normal normal 50px/76px "Roboto", "Helvetica", "Arial", sans-serif !important;
}

a {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    color: #52ABE8 !important;
}

.verified {
    color: #2ecc71;
}

.un-verified {
    color: #e74c3c;
}

.verified {
    color: #2ecc71;
}

.un-verified {
    color: #e74c3c;
}

.btn-approve {
    background-color: #00D9A6 !important;
    color: #fff !important;
}

.btn-delete {
    background-color: #e74c3c !important;
    color: #fff !important;
}

table.details {
    width: 100%;
}

table.details th {
    width: 20%;
    text-align: left;
}

table.details td {
    width: 30%;
}

table.details tr, table.details th, table.details td {
    padding: 10px;
    border: 1px solid rgb(128 128 128 / 15%);
}

.other-image {
    text-align: center;
}

.other-image img {
    height: 100px;
}